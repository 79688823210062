import { Close } from '@mui/icons-material';
import { Box, Modal, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { updatePlaceRow } from '../../../redux/features/DocumentFormSlice';

interface EnterQuantityInputModalProps {
  documentRowIndex: number;
  placeRowIndex: number;
  header: string;
}

const QuantityButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 2),
  margin: theme.spacing(1),
  display: 'flex',
  width: '33%'
}));

const AddQuantityButton: typeof Button = (props: any) => (
  <QuantityButton {...props} variant="contained" color="success" />
);

const RemoveQuantityButton: typeof Button = (props: any) => (
  <QuantityButton {...props} variant="contained" color="error" />
);

const EnterQuantityInputModal: FC<EnterQuantityInputModalProps> = (props) => {
  const { header, documentRowIndex, placeRowIndex } = props;
  const [opened, setOpened] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const quantity = useAppSelector(
    (state) =>
      state.documentForm.form?.rows?.[documentRowIndex]?.places?.[placeRowIndex]?.quantity ?? 0
  );
  const productEan = useAppSelector(
    (state) => state.documentForm.form?.rows?.[documentRowIndex]?.product?.ean ?? null
  );

  const [value, setValue] = useState<number>(quantity);

  useEffect(() => {
    if (!opened) {
      setValue(quantity);
    }
  }, [opened]);

  const handleClose = () => {
    dispatch(
      updatePlaceRow({
        documentRowIndex,
        placeRowIndex,
        field: 'quantity',
        data: value
      })
    );
    setOpened(false);
  };

  const handleNumberInputChange = (val: any) => {
    if (!val.hasOwnProperty('target')) {
      return;
    }

    setValue(parseInt((val?.target?.value ?? '') + ''));
  };

  return (
    <>
      <Modal open={opened} onClose={() => setOpened(false)}>
        <Box>
          <Box>
            <Box
              sx={(theme) => ({
                position: 'relative',
                padding: 2,
                textAlign: 'center',
                maxWidth: '480px',
                margin: '0 auto',
                backgroundColor: theme.palette.background.paper
              })}>
              <Button
                sx={{ position: 'absolute', top: 10, right: 4 }}
                className={'close-btn'}
                onClick={() => setOpened(false)}>
                <Close />
              </Button>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, textAlign: 'center', padding: '10px', marginTop: 3 }}>
                {header}
              </Typography>
              <Box>
                <Box display="flex" justifyContent="center" padding={1}>
                  <OutlinedInput
                    value={value}
                    type="number"
                    sx={{ width: '100%' }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    onChange={handleNumberInputChange}
                    startAdornment={
                      <Button variant="contained" color="error" onClick={() => setValue(value - 1)}>
                        -
                      </Button>
                    }
                    endAdornment={
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setValue(value + 1)}>
                        +
                      </Button>
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <AddQuantityButton onClick={() => setValue(value + 1)}>+1</AddQuantityButton>
                  <AddQuantityButton onClick={() => setValue(value + 5)}>+5</AddQuantityButton>
                  <AddQuantityButton onClick={() => setValue(value + 10)}>+10</AddQuantityButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  <AddQuantityButton onClick={() => setValue(value + 50)}>+50</AddQuantityButton>
                  <AddQuantityButton onClick={() => setValue(value + 100)}>+100</AddQuantityButton>
                  <AddQuantityButton onClick={() => setValue(value + 1000)}>
                    +1000
                  </AddQuantityButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  <RemoveQuantityButton onClick={() => setValue(value - 1)}>
                    -1
                  </RemoveQuantityButton>
                  <RemoveQuantityButton onClick={() => setValue(value - 5)}>
                    -5
                  </RemoveQuantityButton>
                  <RemoveQuantityButton onClick={() => setValue(value - 10)}>
                    -10
                  </RemoveQuantityButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  <RemoveQuantityButton onClick={() => setValue(value - 50)}>
                    -50
                  </RemoveQuantityButton>
                  <RemoveQuantityButton onClick={() => setValue(value - 100)}>
                    -100
                  </RemoveQuantityButton>
                  <RemoveQuantityButton onClick={() => setValue(value - 1000)}>
                    -1000
                  </RemoveQuantityButton>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" padding={2}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Zatwierdź
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{ padding: 1 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpened(true);
        }}>
        {quantity}
      </Box>
    </>
  );
};

export default EnterQuantityInputModal;
