import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import {
  Warehouse,
  GenericApiPlatformCollectionQueryParameters,
  GenericApiPlatformCollectionResponse,
  Place
} from './Types';
import { login } from '../features/UserSlice';
import { userApi } from './UserApi';
import { documentTypesApi } from './DocumentTypesApi';
import { appContext } from '../features/AppContext';
import { RootState } from '../Store';

export const warehousesApi = createApi({
  reducerPath: 'warehouse',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['Warehouse'],
  endpoints: (builder) => ({
    getPaginatedList: builder.query<
      GenericApiPlatformCollectionResponse<Warehouse>,
      GenericApiPlatformCollectionQueryParameters
    >({
      query(params) {
        return {
          url: '/warehouse/warehouses',
          params
        };
      }
    }),
    getAll: builder.query<GenericApiPlatformCollectionResponse<Warehouse>, null>({
      query() {
        return {
          url: '/warehouse/warehouses?page=1&limit=1000'
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const state: RootState = getState();

          if (data['hydra:member'].length > 0) {
            if (state.appContext.warehouse) {
              dispatch(
                appContext.actions.pickWarehouse(
                  data['hydra:member'].find(
                    (w) => w['@id'] === state.appContext.warehouse['@id']
                  ) ?? data['hydra:member'][0]
                )
              );
            } else {
              dispatch(appContext.actions.pickWarehouse(data['hydra:member'][0]));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getPlaces: builder.query<GenericApiPlatformCollectionResponse<Place>, { warehouseId: string }>({
      query(params) {
        return {
          url: '/warehouse/places?page=1&limit=10000&warehouse=' + params.warehouseId
        };
      }
    })
  })
});
