import { Contractor } from '../../../redux/api/Types';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

function ContractorLabel({ name, tin }: Pick<Contractor, 'name' | 'tin'>) {
  return (
    <div>
      <Typography variant="body1" color="text.primary" align="center">
        {name}
      </Typography>
      <Grid flexWrap="wrap" justifyContent="space-between" sx={{ display: 'flex' }}>
        <Grid item={true}>
          <Typography variant="body2" color="text.secondary">
            .
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant="body2" color="text.secondary">
            {tin?.number}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(ContractorLabel);
