import { Place, ProductWarehouse, Warehouse } from '../../../redux/api/Types';
import * as React from 'react';
import { memo, useCallback, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Alert, Box, Checkbox, FormControlLabel, Grid, useTheme } from '@mui/material';
import useScanner from '../../../hooks/Scanner';
import { toast } from 'react-toastify';
import SingleListPicker from '../Common/SingleListPicker';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import SingleApiProductListPicker from '../Common/SingleApiProductListPicker';
import SingleApiContractorListPicker from '../Common/SingleApiContractorListPicker';
import ContractorLabel from '../SelectLabels/ContractorLabel';
import ProductBox from './ProductBox';
import { warehousesApi } from '../../../redux/api/WarehousesApi';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { getDocumentTypeColor } from '../../../utils/Colors';
import useIsGranted from '../../../hooks/Security';
import { useWarehouseProducts } from '../../../hooks/WarehouseProducts';
import {
  handleProductScan,
  setIssueDate,
  setRecipient,
  setSkipStockValidation,
  setStocktaking,
  setSupplier
} from '../../../redux/features/DocumentFormSlice';
import { shallowEqual } from 'react-redux';

interface WarehouseDocumentProps {
  isNew?: boolean;
}

const ScannerHandler = (props: { products: ProductWarehouse[]; places: Place[] }) => {
  const [pickerOptions, setPickerOptions] = useState<ProductWarehouse[]>([]);
  const dispatch = useAppDispatch();
  useScanner<ProductWarehouse>(
    (matchedProducts) => {
      if (matchedProducts.length === 0) {
        toast.error('Nie znaleziono produktu ');
        new Audio('/media/error.mp3').play();
        return;
      }
      if (matchedProducts.length === 1) {
        // toast.success('Dodano produkt', { autoClose: 1000 });
        handleSelectProduct(matchedProducts[0]);
        new Audio('/media/success.mp3').play();
        return;
      }
      if (matchedProducts.length > 1) {
        // toast.info(`Znaleziono ${matchedProducts.length}, wybierz jeden towar`, {
        //   autoClose: 3000
        // });
        setPickerOptions(matchedProducts);
        return;
      }
    },
    6,
    props.products,
    (product, code) => product.ean?.trim() === code,
    props.places.map((place) => place.name)
  );

  const handleSelectProduct = (product: ProductWarehouse | null) => {
    setPickerOptions([]);
    if (product === null) {
      return;
    }

    dispatch(handleProductScan(product));
  };

  return (
    <>
      {pickerOptions.length > 1 && (
        <SingleListPicker<ProductWarehouse>
          defaultValue={null}
          options={props.products}
          onChange={handleSelectProduct}
          onClose={() => setPickerOptions([])}
          onCancel={() => setPickerOptions([])}
          openByDefault={true}
          placeholder=""
          label={(item) =>
            !item ? (
              ''
            ) : (
              <div>
                <Typography variant="body1" color="text.primary" align="center">
                  {item.name}
                </Typography>
                <Grid flexWrap="wrap" justifyContent="space-between" sx={{ display: 'flex' }}>
                  <Grid item={true}>
                    <Typography variant="body2" color="text.secondary">
                      {item.ean}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="body2" color="text.secondary">
                      {item.symbol}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )
          }
        />
      )}
    </>
  );
};

const MemoizedScannerHandler = memo(ScannerHandler);

const ScannerHandlerWrapper = () => {
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const { isLoading, products } = useWarehouseProducts({
    typeId: selectedWarehouse?.type?.id ?? undefined
  });
  const { data: placesData } =
    warehousesApi.endpoints?.getPlaces.useQuery(
      { warehouseId: selectedWarehouse?.['@id'] ?? '' },
      { skip: !selectedWarehouse }
    ) ?? {};

  if (isLoading) {
    return 'ładuję dane...';
  }

  return <MemoizedScannerHandler products={products} places={placesData?.['hydra:member'] ?? []} />;
};

const SupplierComponent = () => {
  const defaultSupplier = useAppSelector(
    (state) => state.documentForm.form.type?.defaultSupplier ?? null,
    shallowEqual
  );
  const supplier = useAppSelector(
    (state) => state.documentForm.form.supplier ?? null,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  if (defaultSupplier) {
    return null;
  }
  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ mb: 1, textAlign: 'center' }}>
        <CardContent style={{ padding: 8 }}>Dostawca</CardContent>
        <SingleApiContractorListPicker
          defaultValue={supplier}
          onChange={(val) => dispatch(setSupplier(val))}
          placeholder="Wybierz kontrahenta"
          disabled={false}
          label={(item) => (item ? <ContractorLabel {...item} /> : '')}
        />
      </Card>
    </Grid>
  );
};

const RecipientComponent = () => {
  const defaultRecipient = useAppSelector(
    (state) => state.documentForm.form.type?.defaultRecipient ?? null,
    shallowEqual
  );
  const recipient = useAppSelector(
    (state) => state.documentForm.form.recipient ?? null,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  if (defaultRecipient) {
    return null;
  }
  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ mb: 1, textAlign: 'center' }}>
        <CardContent style={{ padding: 8 }}>Odbiorca</CardContent>
        <SingleApiContractorListPicker
          defaultValue={recipient}
          onChange={(val) => dispatch(setRecipient(val))}
          placeholder="Wybierz kontrahenta"
          disabled={false}
          label={(item) => (item ? <ContractorLabel {...item} /> : '')}
        />
      </Card>
    </Grid>
  );
};

const DocumentRows = () => {
  const rows: number = useAppSelector((state) => (state.documentForm.form?.rows ?? []).length);

  const map = [];

  for (let i = 0; i < rows; i++) {
    map.push(i);
  }

  return (
    <>
      {map.map((row, index) => (
        <ProductBox key={`document_row_${index}`} documentRowIndex={index} />
      ))}
    </>
  );
};

const DocumentDate = () => {
  const issueDate = useAppSelector((state) => state.documentForm.form?.issueDate ?? '');
  const dispatch = useAppDispatch();
  const handleDateChange = (date: any) => {
    dispatch(setIssueDate(date?.format('YYYY-MM-DD') ?? null));
  };

  return (
    <Card sx={{ mb: 1, textAlign: 'center', p: 1 }}>
      <MobileDatePicker
        label="Data wystawienia"
        value={dayjs(issueDate)}
        onChange={handleDateChange}
      />
    </Card>
  );
};

const ProductSelector = () => {
  const dispatch = useAppDispatch();
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const theme = useTheme();

  const handleOnChange = useCallback((product: ProductWarehouse | null) => {
    if (product === null) {
      return;
    }
    dispatch(handleProductScan(product));
  }, []);

  return (
    <SingleApiProductListPicker
      defaultValue={null}
      onChange={handleOnChange}
      defaultFilters={{ type: selectedWarehouse?.type ?? undefined }}
      placeholder=""
      label={(item) =>
        !item ? (
          ''
        ) : (
          <div>
            <Typography variant="body1" color="text.primary" align="center">
              {item.name}
            </Typography>
            <Grid flexWrap="wrap" justifyContent="space-between" sx={{ display: 'flex' }}>
              <Grid item={true}>
                <Typography variant="body2" color="text.secondary">
                  {item.ean}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant="body2" color="text.secondary">
                  {item.symbol}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )
      }>
      <div style={{ padding: theme.spacing(2) }}>
        <Card sx={{ background: theme.palette.primary.main, color: '#fff', textAlign: 'center' }}>
          <CardContent>
            Kliknij aby wybrać towar z listy
            <br />
            <small>Lub użyj czytnika kodów z telefonu</small>
          </CardContent>
        </Card>
      </div>
    </SingleApiProductListPicker>
  );
};

const DocumentStocktaking = () => {
  const stocktaking = useAppSelector((state) => state.documentForm.form?.stocktaking === true);
  const type = useAppSelector((state) => state.documentForm.form?.type ?? null, shallowEqual);
  const dispatch = useAppDispatch();

  const handleToggle = useCallback(() => {
    dispatch(setStocktaking(!stocktaking));
  }, [stocktaking]);

  return (
    <>
      {type?.internal && type?.placeEvent === 'STORE' && (
        <Grid item xs={12} md={6}>
          <Card sx={{ mb: 1, textAlign: 'center' }}>
            <FormControlLabel
              control={<Checkbox defaultChecked={stocktaking} />}
              label="Inwentaryzacja miejsca magazynowego"
              onChange={handleToggle}
            />
            {stocktaking && (
              <Alert sx={{ textAlign: 'left' }} severity={'info'}>
                Rozpoczynasz inwentaryzację miejsca magazynowego. <br />
                Wszystkie produkty na tym dokumencie muszą zawierać to samo miejsce magazynowe.{' '}
                <br />
                Przed przeprocesowaniem dokumentu, miejsce magazynowe zostanie wyczyszczone.
              </Alert>
            )}
          </Card>
        </Grid>
      )}
    </>
  );
};

const DocumentSkippedStockValidation = () => {
  const skipStockValidation = useAppSelector(
    (state) => state.documentForm.form?.skipStockValidation == true
  );
  const dispatch = useAppDispatch();
  const isGrantedSkipStockValidation = useIsGranted('ROLE_SKIP_VALIDATION_WAREHOUSE_DOCUMENT');

  const handleToggle = useCallback(() => {
    dispatch(setSkipStockValidation(!skipStockValidation));
  }, [skipStockValidation]);

  if (!isGrantedSkipStockValidation) {
    return null;
  }
  return (
    <>
      <Grid item xs={12} md={6}>
        <Card sx={{ mb: 1, textAlign: 'center' }}>
          <FormControlLabel
            control={<Checkbox defaultChecked={skipStockValidation} />}
            label="Pomiń walidacje stanów magazynowych"
            onChange={handleToggle}
          />
        </Card>
      </Grid>
    </>
  );
};

const NewDocumentLabel = () => {
  const type = useAppSelector((state) => state.documentForm.form?.type ?? null, shallowEqual);
  return (
    <Card
      sx={{
        mb: 1,
        textAlign: 'center',
        backgroundColor: getDocumentTypeColor(type?.name ?? ''),
        color: '#fff',
        borderRadius: 0,
        position: 'sticky',
        top: 0,
        zIndex: 2
      }}>
      <CardContent style={{ padding: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Nowy dokument - {type?.name}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const MemoizedScannerHandlerWrapper = memo(ScannerHandlerWrapper);
function WarehouseDocumentForm(props: WarehouseDocumentProps) {
  return (
    <>
      <MemoizedScannerHandlerWrapper />
      {props.isNew && <NewDocumentLabel />}
      <Box sx={{ position: 'relative', paddingBottom: '56px' }}>
        <Grid container spacing={1}>
          <SupplierComponent />
          <RecipientComponent />
          <DocumentStocktaking />
          <DocumentSkippedStockValidation />
        </Grid>
        <Card sx={{ mb: 1, textAlign: 'center', p: 1 }}>
          <DocumentDate />
        </Card>
        <Card
          sx={{
            mb: 1,
            textAlign: 'center',
            backgroundColor: (theme) => theme.palette.primary.main,
            color: '#fff',
            borderRadius: 0
          }}>
          <CardContent style={{ padding: 8 }}>Pozycje</CardContent>
        </Card>

        <Grid container>
          <DocumentRows />
        </Grid>

        <ProductSelector />
      </Box>
    </>
  );
}

const MemoizedWarehouseDocumentForm = memo(WarehouseDocumentForm);
export default MemoizedWarehouseDocumentForm;
