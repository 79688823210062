import { ApiPlatformRecordResponse } from '../../../redux/api/Types';
import { ChangeEvent, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal/Modal';
import Box from '@mui/material/Box/Box';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import CardActions from '@mui/material/CardActions/CardActions';
import IconButton from '@mui/material/IconButton/IconButton';
import { DeleteForever, HighlightOff } from '@mui/icons-material';
import {
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export interface SingleListPickerProps<T extends ApiPlatformRecordResponse> {
  title: string;
  defaultValue: T | null;
  options: T[];
  onChange: (value: T | null) => void;
  onClose?: () => void;
  onCancel?: () => void;
  label: (value: T | null) => string | JSX.Element;
  selectedLabel?: (value: T | null) => string | JSX.Element;
  parseOptionToString?: (value: T) => string;
  placeholder: string;
  openByDefault?: boolean;
  loading?: boolean;
  clearable?: boolean;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'open'
})<ListItemProps>(({ theme }) => ({
  borderBottom: '1px solid '.concat(theme.palette.divider),
  cursor: 'pointer'
}));

interface SingleListPickerOptionItemProps {
  value: string;
  selected: boolean;
  onClick: () => void;
  label: string | JSX.Element;
  onDoubleClick: () => void;
}

function SingleListPickerOptionItem(props: SingleListPickerOptionItemProps) {
  const { value, selected, label, onClick, onDoubleClick } = props;
  return (
    <ListItem
      key={value}
      style={{ ...(selected ? { background: 'rgba(46, 125, 50, 0.2)' } : {}) }}
      alignItems="flex-start"
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      <ListItemText primary={label} />
    </ListItem>
  );
}

export default function SingleListPicker<T extends ApiPlatformRecordResponse>(
  props: SingleListPickerProps<T>
) {
  const [value, setValue] = useState<string | null>(props.defaultValue?.['@id'] ?? null);
  const [pickerOpened, setPickerOpened] = useState<boolean>(props.openByDefault ?? false);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setValue(props.defaultValue?.['@id'] ?? null);
  }, [props.defaultValue?.['@id'] ?? null]);
  useEffect(() => {
    if (!pickerOpened) {
      props.onClose?.();
    }
  }, [pickerOpened]);

  const selectedOption = props.options.find((option) => option['@id'] === value) ?? null;

  const pickValue = (value: T | null, save: boolean = false) => {
    setValue(value?.['@id'] ?? null);
    if (save) {
      props.onChange(value);
      setPickerOpened(false);
    }
  };
  const onCancel = () => {
    setValue(props.defaultValue?.['@id'] ?? null);
    setPickerOpened(false);
    props.onCancel?.();
  };

  const onAccept = () => {
    props.onChange(selectedOption);
    setPickerOpened(false);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Modal open={pickerOpened} onClose={() => onCancel()}>
        <Box bgcolor="#fff" position="fixed" left={0} top={0} height="100vh" width="100vw">
          <Card sx={{ minWidth: '100%', height: '100%' }} style={{ position: 'relative' }}>
            <CardHeader
              sx={{ padding: 1 }}
              action={
                <IconButton aria-label="settings" onClick={() => onCancel()}>
                  <HighlightOff />
                </IconButton>
              }
              title={props.title}
            />
            {props.parseOptionToString && (
              <TextField
                placeholder={'Szukaj...'}
                value={searchText}
                onChange={handleSearchTextChange}
                InputProps={{
                  endAdornment:
                    searchText.length > 0 ? (
                      <InputAdornment position="start">
                        <DeleteForever />
                      </InputAdornment>
                    ) : null,
                  inputProps: { autoFocus: true }
                }}
              />
            )}
            {/*{pickerOpened && (*/}
            <CardContent
              sx={{ overflowY: 'auto', p: 0 }}
              style={{ height: `calc(100vh - 48px - 48px)`, maxHeight: '84%' }}>
              <List sx={{ width: '100%', padding: 0, paddingBottom: 2 }}>
                {props.clearable && props.defaultValue && (
                  <ListItem
                    key={value}
                    style={{
                      ...(value === null ? { background: 'rgba(46, 125, 50, 0.2)' } : {})
                    }}
                    alignItems="flex-start"
                    onClick={() => pickValue(null)}
                    onDoubleClick={() => pickValue(null, true)}>
                    <ListItemText primary={'Wyczyść wartość pola'} />
                  </ListItem>
                )}
                {props.loading && (
                  <ListItem key="loading" alignItems="flex-start" onClick={() => {}}>
                    <ListItemAvatar>
                      <CircularProgress />
                    </ListItemAvatar>
                    <ListItemText primary={'Proszę czekać, trwa ładowanie...'} />
                  </ListItem>
                )}
                {!props.loading &&
                  props.options
                    .filter(
                      (el) =>
                        props
                          .parseOptionToString?.(el)
                          .toLowerCase()
                          .includes(searchText.toLowerCase()) ?? true
                    )
                    .slice(0, 100)
                    .map((option) => (
                      <SingleListPickerOptionItem
                        key={option['@id']}
                        value={option['@id']}
                        selected={value === option['@id']}
                        onClick={() => pickValue(option)}
                        onDoubleClick={() => pickValue(option, true)}
                        label={props.label(option)}
                      />
                    ))}
              </List>
            </CardContent>
            {/*)}*/}
            <CardActions
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                top: 'initial',
                zIndex: 99,
                background: '#fff'
              }}>
              <ButtonGroup sx={{ width: '100%' }} size="small">
                <Button sx={{ width: '40%' }} color="error" onClick={onCancel}>
                  Anuluj
                </Button>
                <Button
                  sx={{ width: '100%' }}
                  color="success"
                  variant="contained"
                  onClick={onAccept}>
                  Zatwierdź
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        </Box>
      </Modal>
      <div onClick={() => setPickerOpened(true)}>
        {value ? (props.selectedLabel ?? props.label)(selectedOption) : props.placeholder}
      </div>
    </>
  );
}

SingleListPicker.defaultProps = {
  title: 'Wybierz wartość',
  defaultValue: null,
  options: [],
  onChange: () => {},
  label: (value: ApiPlatformRecordResponse) => value.relationFieldLabel ?? '',
  placeholder: 'Wybierz wartość',
  openByDefault: false
};
