import { useNavigate, useParams } from 'react-router-dom';
import { documentTypesApi } from '../../redux/api/DocumentTypesApi';
import FullScreenLoader from '../../components/ui/FullScreenLoader';
import { ErrorBox404 } from '../../components/ui/ErrorBox';
import { Alert, BottomNavigation, BottomNavigationAction, Box, Grid } from '@mui/material';
import WarehouseDocumentForm from '../../components/form/WarehouseDocument/WarehouseDocumentForm';
import {
  useCreateDocumentMutation,
  useGetItemQuery,
  useUpdateDocumentMutation
} from '../../redux/api/DocumentsApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Warehouse, WarehouseDocument } from '../../redux/api/Types';
import { DocumentForm } from '../../components/form/WarehouseDocument/Types';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import * as React from 'react';
import DocumentStatusLabel from '../../components/form/SelectLabels/DocumentStatusLabel';
import { getDocumentTypeColor } from '../../utils/Colors';
import { initForm } from '../../redux/features/DocumentFormSlice';
import { ArrowBackIos, Save } from '@mui/icons-material';

const ActionButtons = ({ initialDocument }: { initialDocument: WarehouseDocument }) => {
  const navigate = useNavigate();
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const currentUserId = useAppSelector((state) => state.userState.user.id);
  const documentData: DocumentForm = useAppSelector((state) => state.documentForm.form);

  const [
    updateExistingDocument,
    {
      isLoading: isSavingExisting,
      data: updatedDocument,
      isError: isErrorExisting,
      error: errorExisting,
      isSuccess: isSuccessExisting
    }
  ] = useUpdateDocumentMutation();

  useEffect(() => {
    if (isSavingExisting) {
      toast.info('Zapisywanie w toku', { autoClose: 500 });
      return;
    }
    if (isSuccessExisting) {
      toast.success('Zaktualizowano dokument', { autoClose: 2500 });
      new Audio('/media/zapisano_dokument.wav').play();
    }
    if (isErrorExisting) {
      new Audio('/media/wystapil_problem_z_zapisem_dokumentu.wav').play();
      if (((errorExisting as any).status as number) === 422) {
        ((errorExisting as any).data.violations as any).forEach((el: any) =>
          toast.error(`${el.propertyPath}: ${el.message}`, {
            position: 'top-right',
            autoClose: 4500
          })
        );
      }
    }
  }, [isSavingExisting]);
  const onCancel = () => {
    if (typeof initialDocument.type === 'object') {
      navigate(`/app/warehouse/documents/list/${initialDocument.type.id}`);
    } else {
      navigate(`/app/warehouse/dashboard`);
    }
  };

  const onSubmit = () => {
    const newDocument: Partial<WarehouseDocument> = {
      recipient: documentData.recipient?.['@id'] ?? null,
      supplier: documentData.supplier?.['@id'] ?? null,
      issuer: `/employee/employees/${currentUserId}`,
      type: documentData.type?.['@id'] ?? null,
      currency: documentData.currency,
      warehouse: initialDocument.warehouse ?? selectedWarehouse?.['@id'] ?? null,
      issueDate: documentData.issueDate,
      rows: [
        ...documentData.rows
          .map((row) =>
            row.places.map((place) => ({
              '@id': place.currentIRI,
              product: row.product?.['@id'] ?? null,
              unit: null,
              quantity: place.quantity,
              place: place.place?.['@id'] ?? null,
              unitPriceNet: Math.round(row.unitPriceNet * 100),
              unitPriceGross: Math.round(row.unitPriceGross * 100),
              vat: row.vat
            }))
          )
          .flat()
      ],
      totalPriceNet: Math.round(computeTotalPrice(documentData.rows, 'unitPriceNet') * 100),
      totalPriceGross: Math.round(computeTotalPrice(documentData.rows, 'unitPriceGross') * 100),
      totalPriceVat: Math.round(computeTotalPrice(documentData.rows, 'unitPriceVat') * 100),
      place: 'Tomaszów Mazowiecki',
      stocktaking: documentData.stocktaking,
      skipStockValidation: documentData.skipStockValidation
    };

    updateExistingDocument({ '@id': initialDocument['@id'], data: newDocument });
  };

  const computeTotalPrice = (
    rows: DocumentForm['rows'],
    field: 'unitPriceNet' | 'unitPriceGross' | 'unitPriceVat'
  ) => {
    return rows.reduce((prev, curr) => {
      const quantity = curr.places.reduce((prev, curr) => prev + curr.quantity, 0);
      if (field === 'unitPriceVat') {
        return prev + (curr['unitPriceGross'] - curr['unitPriceNet']) * quantity;
      }
      return prev + curr[field] * quantity;
    }, 0);
  };

  return (
    <Box
      sx={{
        mb: 0,
        width: '100%',
        textAlign: 'center',
        borderRadius: 0,
        borderTop: (theme) => `1px ridge ${theme.palette.primary.main}`,
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 2
      }}>
      <BottomNavigation
        showLabels
        value={null}
        onChange={(event, newValue) => false}
        sx={{ justifyContent: 'space-between' }}>
        <BottomNavigationAction label="Anuluj" icon={<ArrowBackIos />} onClick={onCancel} />
        <BottomNavigationAction label="" icon={null} />
        <BottomNavigationAction label="Zapisz" icon={<Save color="success" />} onClick={onSubmit} />
      </BottomNavigation>
    </Box>
  );
};

export default function () {
  const params = useParams() as { documentId: string };
  const {
    isError,
    isFetching,
    isLoading,
    data: existingDocument
  } = useGetItemQuery({ id: params.documentId });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (existingDocument !== undefined) {
      dispatch(initForm(transformDocumentData(existingDocument)));
    }
  }, [existingDocument]);

  if (isFetching || isLoading) {
    return <FullScreenLoader />;
  }

  if (isError || existingDocument === undefined) {
    return <ErrorBox404 content="Dokument nie został odnaleziony" />;
  }

  const transformDocumentData = (documentData: WarehouseDocument): DocumentForm => {
    if (
      typeof documentData.type !== 'object' ||
      typeof documentData.supplier !== 'object' ||
      typeof documentData.recipient !== 'object'
    ) {
      throw new Error('Document type is not an object');
    }

    const rows: DocumentForm['rows'] = [];

    documentData.rows.forEach((row) => {
      const product = row.product;
      const place = row.place;
      if (typeof product !== 'object' || typeof place !== 'object') {
        throw new Error('Document type is not an object');
      }
      const existingRow = rows.find((el) => el.product?.['@id'] === product?.['@id']);
      if (existingRow) {
        const existingPlace = existingRow.places.find((el) => el.place?.['@id'] === place?.['@id']);
        if (existingPlace) {
          existingPlace.quantity += row.quantity ?? 0;
        } else {
          existingRow.places.push({
            currentIRI: row['@id'],
            place,
            quantity: row.quantity ?? 0
          });
        }
      } else {
        rows.push({
          product,
          unitPriceNet: (row.unitPriceNet ?? 0) / 100,
          unitPriceGross: (row.unitPriceGross ?? 0) / 100,
          vat: row.vat ?? 0,
          places: [
            {
              currentIRI: row['@id'],
              place,
              quantity: row.quantity ?? 0
            }
          ]
        });
      }
    });

    return {
      recipient: documentData.recipient,
      supplier: documentData.supplier,
      type: documentData.type,
      currency: documentData.currency,
      issueDate: documentData.issueDate,
      stocktaking: documentData.stocktaking,
      rows
    };
  };

  return (
    <div>
      <Card
        sx={{
          mb: 1,
          textAlign: 'center',
          backgroundColor: (theme) => theme.palette.primary.main,
          color: '#fff',
          borderRadius: 0,
          position: 'sticky',
          top: 0,
          zIndex: 2
        }}>
        <CardContent style={{ padding: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <span
                style={{
                  padding: '6px 12px',
                  borderRadius: 6,
                  backgroundColor:
                    typeof existingDocument.type === 'object'
                      ? getDocumentTypeColor(existingDocument.type.name)
                      : '#303030'
                }}>
                {existingDocument.number}
              </span>
            </Grid>
            <Grid item xs={6}>
              <DocumentStatusLabel status={existingDocument.status} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <WarehouseDocumentForm />
      <ActionButtons initialDocument={existingDocument ?? {}} />
    </div>
  );
}
